<template>
  <div class="login">
    <div class="lang">
      <span
        class="show-lang"
        style="margin-top: 10px;
    margin-right: 7px;"
        @click="switchLang('EN')"
        >EN</span
      >
      <span
        class="show-lang"
        style="margin-top: 10px;"
        @click="switchLang('TH')"
        >TH /</span
      >
    </div>
    <div class="container">
      <img src="@/assets/scgplogo.png" class="logo" />
      <div class="container"></div>

      <p class="description">Covid-19 Self Assessment</p>
      <p class="description2">{{ showDescription }}</p>

      <!-- <div class="col-lg-6"> -->
      <button
        class="btn btn-sm"
        style="width: 260px; background-color:#045A16; margin-bottom: 10px; margin-right:10px; margin-left:24px;"
        @click="openModalEmployee"
      >
        <p class="description1">{{ showTextBtnAd }}</p>
      </button>

      <button
        class="btn btn-sm"
        style="width: 260px; background-color:#0B294F; margin-bottom: 10px; margin-left:14px;"
        @click="openModalVendor"
      >
        <p class="description1">{{ showTextBtnVender }}</p>
      </button>
      <!-- </div> -->
    </div>
    <ModalLoginAd
      v-if="showModalEmployee"
      :showModal="showModalEmployee"
      @closeModal="showModalEmployee = false"
    ></ModalLoginAd>
    <ModalLoginVender
      v-if="showModalVender"
      :showModal="showModalVender"
      @closeModal="showModalVender = false"
    ></ModalLoginVender>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const ModalLoginAd = defineAsyncComponent(() =>
  import("./components/modalLoginAd")
);
const ModalLoginVender = defineAsyncComponent(() =>
  import("./components/modalLoginVender")
);
export default {
  components: {
    ModalLoginAd,
    ModalLoginVender,
  },
  data() {
    return {
      showModalEmployee: false,
      showModalVender: false,
      descriptionForm: {
        th:
          "ขอขอบคุณพนักงานทุกท่าน ที่ให้ข้อมูลตามความเป็นจริง เพื่อความปลอดภัยของท่านและเพื่อนร่วมงานทุกคน",
        en:
          "Thank you all staff for giving the actual information for your and your colleague safety.",
      },
      textBtnAd: {
        th: "พนักงาน SCGP/JV/คู่ธุรกิจที่มี e-mail @scg.com",
        en: "SCG STAFF/JV/Business Partner who has e-mail @scg.com",
      },
      textBtnVender: {
        th: "พนักงาน SCGP/JV/คู่ธุรกิจที่ไม่มี e-mail @scg.com",
        en: " SCG STAFF/JV/Business Partner who does not have e-mail @scg.com",
      },
      mode: localStorage.getItem("lang") || "TH",
    };
  },
  computed: {
    showTextBtnAd() {
      if (this.mode === "EN") return this.textBtnAd.en;
      else return this.textBtnAd.th;
    },
    showTextBtnVender() {
      if (this.mode === "EN") return this.textBtnVender.en;
      else return this.textBtnVender.th;
    },
    showDescription() {
      if (this.mode === "EN") return this.descriptionForm.en;
      else return this.descriptionForm.th;
    },
  },
  methods: {
    switchLang(data) {
      localStorage.setItem("lang", data);
      this.mode = data;
    },
    openModalEmployee() {
      this.showModalEmployee = true;
    },
    openModalVendor() {
      this.showModalVender = true;
    },
  },
};
</script>

<style lang="scss">
.login {
  .container .description {
    font-size: 30px;
    margin-top: -40px;
    margin-bottom: 65px;
    color: #808080;
  }
  .container .description2 {
    font-size: 22px;
    margin-top: -40px;
    margin-bottom: 45px;
    color: #0b294f;
  }
  .container .description1 {
    width: 245px;
    font-size: 22px;
    color: #ffffff;
    margin-top: 10px;
  }
  .container {
    text-align: center;
    // display: flex;
    // font-size: 14px;
    // justify-content: center;
  }
  // .bg {
  //   width: 150px;
  //     height: 100vh;
  //     padding-top: 150px;
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     background-size: cover;
  // }
  .logo {
    width: 235px;
    margin-bottom: 20px;
    background-position: center;
  }
}
</style>
